import { InputNumber, Button, Table, Space, message, Form, Image, Select, Empty, Spin, DatePicker, Row, Col } from 'antd';
import constants from '../../constants/constants';
import React from "react";
import { useEffect } from 'react';
import APIUtils from '../../api/APIUtils';
import Cookies from 'js-cookie';
import WebLinks from '../../api/WebLinks';
import { useState } from 'react';
import { GetCustomPriorityLevelIcons, GetPriorityLevelColors, GetPriorityLevelIcons } from '../../globalFunctions/GlobalFunctions';
import { useContext } from 'react';
import { CurrencyContext } from '../../Context';
import moment from 'moment';
import dayjs from 'dayjs';

const ContractsSpend = () => {
	const currencyContext=useContext(CurrencyContext);
	const [Loading, setLoading] = useState(false);
	const [data, setData] = useState([]);
	const [total, setTotal] = useState(0);
	const [currentPage, setCurrentPage] = useState(1);
	const [form] = Form.useForm();
  const [pickerform] = Form.useForm();
  const [pickerValue, setpickerValue] = useState(null);
	const columns = [
		{
			dataIndex: 'suppliers',
			key: 'suppliers',
			width: '15%',
			render: (text, record, index) => <>
				{record.logoURL && record.logoURL !== "" ? <Image src={record.logoURL} preview={false} width={100} /> : <span className='uppercase font-bold text-gray-400'>{record.supplierName} Budget</span>}
			</>
		},
		{
			dataIndex: 'budgetCategory',
			key: 'budgetCategory',
			width: '10%',
			render: (text, record, index) => <span className='font-bold text-gray-400'>
				<Select className='select-readonly' disabled value={record.budget} onChange={(e)=>budgetCategoryChange(e,index,record?.supplierId)} options={[{ value: true, label: <span>Budget</span> },{ value: false, label: <span>Distribute</span> }]} />
			</span>,
		},
		{
			title: <span className='uppercase font-bold'>Spend</span>,
			align: "left",
      dataIndex: "contractSpend",
      key:"contractSpend",
     render: (text, record, index) => (
        <div className='justify-center items-center'>
              {/* <Form.Item name={'contractSpend' + index} value={text} rules={[
                {
                  required: true,
                  message: "Required field",
                },
              ]}
							className='!mb-0'
							> */}
                <InputNumber size="large" min={0} defaultValue={record?.contractSpend||0} onChange={(e) => handleValueChange(e, index)} className='w-44' addonAfter={<Button className='warning-btn uppercase font-bold' onClick={(e) => updateBudget('contractSpend', index, record)}>{constants.UpdateBtn}</Button>} />
               {/* </Form.Item> */}
        </div> 
      ), 
		}
	];

	const budgetCategoryChange=async(e,index,supplierId)=>{
		// setLoading(true);
		// let payload=null;
		// payload={
		// 	buyerId: Cookies.get(constants.COOKIE_SELECTED_BUYER_ID),
		// 	supplierId: supplierId,
		// 	budget: e?1:0,
		// }
		// const response = await APIUtils.apiCall('PUT', WebLinks.UpdateAllowedBodgetSupplier,JSON.stringify(payload) );
		// if (!response.isCatch && response.resultObj.statusCode === "2000") {
		// 	let tempData= [...data];
		// 	tempData[index].budget=e;
		// 	setData(tempData);
		// 	setLoading(false);
		// } else {
		// 	message.error(response.resultObj.message);
		// 	setLoading(false);
		//}
	}

	const handleValueChange=(value,index)=>{
		let tempData=data;
		tempData[index].contractSpend=value?value:0;
		setData(tempData);
	}

	const getData = async (currentpage = 1,monthValue) => {
		setLoading(true);
		const response = await APIUtils.apiCall('GET', WebLinks.GetContractsSpend + Cookies.get(constants.COOKIE_SELECTED_BUYER_ID) +"&startDate="+monthValue+"&endDate="+monthValue+ "&limit=" + constants.pageSizeBudgets + "&pageNo=" + currentpage);
		if (!response.isCatch && response.resultObj.statusCode === "2000") {
			//Start: Added unique key to fix all inner tables opening together
			response.resultObj.data.map((item, index) => {
				item.key = index;
			})
			//end
			setData(response.resultObj.data);
			setTotal(response.resultObj.getCount);
		} else {
			setData([]);
      setTotal(0);
			message.error(response.resultObj.message);
		}
		setLoading(false);
	};
	const onchangeBudgetTable = (pagination, filters, sorter) => {
		if (pagination) {
			setCurrentPage(pagination?.current);
			getData(pagination?.current,pickerValue);
		}
	};
	const updateBudget = async (col, index, record, sub = false) => {
		setLoading(true);
		const payload = {
			// id: 0,
			buyerId: parseInt(Cookies.get(constants.COOKIE_SELECTED_BUYER_ID)),
			supplierId: record.supplierId,
			month: record.month,
			year: record.year,
			contractSpend:record.contractSpend,
			budget: record.budget,
			contractSpendDate:pickerValue,
		}
		const response = await APIUtils.apiCall('POST', WebLinks.UpdateContractSpendValue, JSON.stringify(payload));
		if (!response.isCatch && response.resultObj.statusCode === "2000") {
			message.success(response?.resultObj?.message||constants.SUCCESS_SAVE);
		} else {
			message.error(response?.resultObj?.message||constants.FAILED_SAVE);
		}
		setLoading(false);
	};
  // Disable all dates aftercurrent month
  const disabledDate = (current) => {
    return current && current >= dayjs().startOf('month');
  };
  const onMonthChange = (date, dateString) => {
    setCurrentPage(1);
		setpickerValue(dateString);
    getData(1,dateString);
  };

	useEffect(() => {
    let monthValue=dayjs().subtract(1, 'month').format(constants.MonthFormat);
		getData(1,monthValue);
    pickerform.setFieldsValue({
      "datePicker": dayjs().subtract(1, 'month')
    })
    setpickerValue(monthValue)
	}, [])
	return (
		<div>
			<Spin spinning={Loading}>
				<div>
					<Space size={'large'}>
						<Form form={pickerform}>
							<Form.Item name="datePicker" className='w-40 mb-0 '>
								<DatePicker picker="month"
									disabledDate={disabledDate}
									format={constants.MonthFormat}
									onChange={onMonthChange} />
							</Form.Item>
						</Form>
						<span className='font-semibold text-xs'>{constants.CONTRACT_SPEND_INFO_MSG}</span>
					</Space>
				</div>
				<div className='mt-3 overflow-x-auto'>
					<Form form={form}>
						{data.length>0 && !Loading?
						<Table
							className='budgets-table'
							size="middle"
							loading={Loading}
							columns={columns}
							dataSource={data}
							scroll={{ x: 965, /* y: 450 */ }}
							pagination={{
								pageSize: constants.pageSizeBudgets,
								total: total,
								onChange: onchangeBudgetTable
							}}
						/>:<Empty/>}
					</Form>
				</div>
			</Spin>
		</div>
	);
};

export default ContractsSpend;
