import { Tabs, Divider } from 'antd';
import { CalendarOutlined, ProfileOutlined ,RocketOutlined } from '@ant-design/icons';
import YourJobsAll from '../YourJobsAll';
import YourJobsPerformance from './YourJobPerformance';
import JobboardBoosts from './JobboardBoosts';
import { useState,useEffect } from 'react';
import WebLinks from '../../api/WebLinks';
import Cookies from 'js-cookie';
import constants from '../../constants/constants';
import APIUtils from '../../api/APIUtils';
const { TabPane } = Tabs;

const YourJobsContainer = () => {
 
  //Start:For Testing only(Remove once JobboardBoost is fully complete)
  const [loader,setLoader]=useState(false);
  const [showBoost,setShowBoost]=useState(false);
  useEffect(() => {
    getBuyerDetails();
  }, []);

  const getBuyerDetails = async () => {
    setLoader(true);
    const response = await APIUtils.apiCall('GET', WebLinks.GetBuyerDetails + Cookies.get(constants.COOKIE_SELECTED_BUYER_ID));
    if (!response.isCatch && response.resultObj?.statusCode === "2000") {
      setShowBoost(response.resultObj?.data?.showJobBoardBoost===1?true:false);
      setLoader(false);
    } else {
      setLoader(false);
    }
  };
  //End
	//Render
	return (
		<div>
      {/* <div className='uppercase font-bold text-xl'>Your Jobs</div> */}
			{/* <Divider className='my-3'/> */}
			<Tabs defaultActiveKey="1">
			<TabPane
        tab={ <span className='uppercase'><CalendarOutlined className='text-xl' />Your Jobs</span> }
        key={1}
      >
        <YourJobsAll/>
      </TabPane>
			<TabPane
        tab={ <span className='uppercase'><ProfileOutlined className='text-xl' />Performance</span> }
        key={2}
      >
        <YourJobsPerformance/>
      </TabPane>
      {showBoost?
      <TabPane
        tab={ <span className='uppercase'><RocketOutlined className='text-xl' />Buy Jobboard boosts</span> }
        key={3}
      >
        <JobboardBoosts/>
      </TabPane>:null}
			</Tabs>
		</div>
	);
};

export default YourJobsContainer;