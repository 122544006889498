import { Space, Select, Table, message, Spin, Tooltip, Button, Divider, Popconfirm } from 'antd';
import { DeleteOutlined, BulbOutlined, UnorderedListOutlined, DollarOutlined } from '@ant-design/icons';
import constants from '../../constants/constants';
import moment from 'moment';
import { useEffect, useState } from 'react';
import WebLinks from '../../api/WebLinks';
import Cookies from 'js-cookie';
import APIUtils from '../../api/APIUtils';
const { Option } = Select
const JobBoardBasketView = (props) => {

	//Variable declaration
	const [loader, setLoader] = useState(false);
	const [jobsCount, setJobsCount] = useState(0);
	const [priorityCheck, setPriorityCheck] = useState("f");
	const [jobBasketData, setJobBasketData] = useState([]);
	const [jobBasketSupplierList, setJobBasketSupplierList] = useState([]);
	const [buyerId,] = useState(Cookies.get(constants.COOKIE_SELECTED_BUYER_ID));

	//On load function call
	useEffect(() => {
		getBuyerPrioritylist();
		jobBoardBoostsBasketList();
		jobBoardBoostsSupplierList();
	}, []);

	//Functions
	//Function to get job bost basket listing.
	const jobBoardBoostsBasketList = async () => {
		setLoader(true);
		let url;
		url = WebLinks.GetJobBoardBoostsViewListing + buyerId;
		const response = await APIUtils.apiCall('GET', url);
		if (!response.isCatch && response.resultObj) {
			setJobBasketData(response.resultObj.data);
			setJobsCount(response.resultObj?.getCount);
		} else {
			setJobBasketData([]);
			setJobsCount(0);
			message.error(response?.resultObj?.message);
		}
		setLoader(false);
	};
	const jobBoardBoostsSupplierList = async () => {
		setLoader(true);
		let url;
		url = WebLinks.GetJobBoardBoostSupplierList + buyerId;
		const response = await APIUtils.apiCall('GET', url);
		if (!response.isCatch && response.resultObj) {
			setJobBasketSupplierList(response.resultObj.data);
		} else {
			setJobBasketSupplierList([])
			message.error(response?.resultObj?.message);
		}
		setLoader(false);
	};
	//Function to get priority list
	const getBuyerPrioritylist = async () => {
		let url = WebLinks.GetBuyerPriorityFilters + buyerId;
		const response = await APIUtils.apiCall('GET', url);
		if (!response.isCatch && response.resultObj) {
			setPriorityCheck(response?.resultObj?.data?.length > 5 ? "t" : "f");
		} else {
			message.error(response?.resultObj?.message);
		}
	};
	// Table columns
	const yourJobsColumns = [
		{
			title: 'id',
			dataIndex: 'id',
			key: 'id',
			hidden: true
		},
		{
			title: 'Priority',
			key: 'priority',
			dataIndex: 'priority',
			align: 'center',
			sorter: (a, b) => a.priority - b.priority,
			render: (_, record) => (
				<Select
					disabled={true}
					//onChange={(e) => singlePriorityChange(e, record.id)}
					value={record.priority}
					className='w-14 select-readonly'
					options={priorityCheck === "t" ? constants.PriorityConstantList2 : constants.PriorityConstantList1}
				/>
			),
		},
		{
			title: 'Job Title',
			dataIndex: 'title',
			key: 'jobTitle',
			sorter: (a, b) => a.title.localeCompare(b.title),
		},
		{
			title: 'Reference',
			dataIndex: 'reference',
			key: 'reference',
			sorter: (a, b) => {
				const referenceA = parseInt(a.reference.match(/\d+/)[0], 10);
				const referenceB = parseInt(b.reference.match(/\d+/)[0], 10);
				return referenceA - referenceB;
			},
		},
		{
			title: 'Location',
			dataIndex: 'place',
			key: 'place',
			sorter: (a, b) => a.place.localeCompare(b.place),
		},
		Table.SELECTION_COLUMN,
		{
			title: 'Select Products',
			key: 'action',
			align: 'center',
			render: (_, record) => (
				<Space>

				</Space>
			),
		},
		{
			title: 'Actions',
			key: 'action',
			align: 'center',
			render: (_, record) => (
				<Space>
					<Popconfirm
						title="Remove Job"
						description="Are you sure to remove this job from basket?"
						onConfirm={() => confirm(record.id)}
						onCancel={cancel}
						okText="Yes"
						cancelText="No"
					>
						<Tooltip title="Remove job from basket" zIndex='1000'>
							<Button size='small' icon={<DeleteOutlined />} type="link" danger />
						</Tooltip>
					</Popconfirm>
				</Space>
			),
		}
	];
	const cancel = (e) => {
	};

	//Function to delete job
	const confirm = async (jobId) => {
		const jobDeleteResponse = await APIUtils.apiCall("DELETE", WebLinks.DeleteJobBoardBasketJob + buyerId + "&jobId=" + jobId);
		if (!jobDeleteResponse.isCatch) {
			message.success(jobDeleteResponse.resultObj.message)
			jobBoardBoostsBasketList();
		} else {
			message.error(jobDeleteResponse.resultObj.message);
		}
	};

	return (
		<>
			<Spin spinning={loader}>
				<div>
					<div className="flex justify-end mb-3 text-xs">
						<Space wrap>
						</Space>
					</div>
					<div className="flex justify-between items-center gap-1">
						<Space>
							<div className="uppercase font-bold text-xl">Your Jobs Boost Basket</div>
							<span>{jobsCount} Jobs Listed </span>{props?.buyerId ? <span className='font-bold text'></span> : null}
						</Space>
					</div>
					<Divider className="my-3" />
					<div className="flex flex-wrap justify-between items-center gap-1 mb-3">
						<Space wrap>
							<Button className='warning-btn uppercase font-bold' icon={<BulbOutlined />}>Recommend Products</Button>
							<span>OR</span>
							<Select
								placeholder="Select Job Board Supplier"
								className="w-full md:w-60"
							>
								<Option value="-1">
									<Space>
										Show All
									</Space>
								</Option>
								{jobBasketSupplierList?.map((item => {
									return (
										<Option value={item.supplierId}>
											<Space>
												{item.name}
											</Space>
										</Option>
									)
								}))}
							</Select>
							<Button icon={<UnorderedListOutlined />}>Show Products</Button>
						</Space>
						<Button type="primary" disabled={jobBasketData?.length>0?false:true} icon={<DollarOutlined />}>Buy selected products</Button>
					</div>
					<div className='overflow-x-auto'>
						<Table
							size='small'
							rowSelection={{
								// type: 'checkbox',
								// ...rowSelection,
								// selectedRowKeys: selectedRowKeys
							}}
							pagination={false}
							rowKey="id"
							columns={yourJobsColumns}
							dataSource={jobBasketData}
						/>
						<div className='text-right mt-3'>
							<Button type="primary" icon={<DollarOutlined />} disabled={jobBasketData?.length>0?false:true}>Buy selected products</Button>
						</div>
					</div>
				</div>
			</Spin>
		</>
	);
};

export default JobBoardBasketView;