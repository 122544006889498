import { Space, Divider, Select, Button, Input, Table, Tooltip, Modal, Form, message, Badge } from 'antd';
import { CheckOutlined, ShoppingCartOutlined, PlusOutlined } from '@ant-design/icons';
import { useState, useEffect } from 'react';
import Cookies from 'js-cookie';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import { GetCustomPriorityLevelIcons, GetPriorityLevelColors, getPriorityCount } from '../../globalFunctions/GlobalFunctions';
import WebLinks from '../../api/WebLinks';
import APIUtils from '../../api/APIUtils';
import constants from '../../constants/constants';
import JobBoardBasketView from './JobBoardBasketView';

const { Search } = Input;
const { Option } = Select

const JobboardBoosts = (props) => {
  //Variable declaration
  const [boostJobsData, setBoostJobsData] = useState([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [boostViewModalOpen, setBoostViewModalOpen] = useState(false);
  const [form] = Form.useForm();
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(200);
  const [searchedValue, setSearchedValue] = useState("");
  const [boostJobsCount, setBoostJobsCount] = useState(0);
  const [buyerId,]=useState(props?.buyerId? props.buyerId:Cookies.get(constants.COOKIE_SELECTED_BUYER_ID));
  const [isClickPost,setIsClickPost]=useState(false);
  const [name,setName]=useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [priorityCountList,setPriorityCountList]=useState([]);
  const [priorityList,setPriorityList]=useState([]);
  const [priorityColorCode,setPriorityColorCode]=useState([]);
  const [priorityCheck,setPriorityCheck]=useState("f");
  const [HiShowPostCode,setHiShowPostCode]=useState(false);
  const [formSearch] = Form.useForm();
  const [formPriority] = Form.useForm();
  const [buyerJobBasketCount,setBuyerJobBasketCount]=useState(0);
  const history = useHistory();

  //Function on page load
  useEffect(() => {
    getBuyerPrioritylist();
    getBuyerDetails();
  }, []);

  //Fetch the jobs boost listing
  const jobBoardBoostsList = async (priorityLevel=null,clickValue) => {
    setIsLoading(true);
    resetStateVariable();
    let url;
      url = WebLinks.GetJobBoardBoostsListing + buyerId;    
    if (priorityLevel && priorityLevel !== "-1") {
      url += "&priorityLevel=" + priorityLevel;
    }
    const response = await APIUtils.apiCall('GET', url);
    if (!response.isCatch && response.resultObj) {
      setBoostJobsData(response.resultObj.data);
      setBoostJobsCount(response.resultObj.getCount);
      setPriorityCountList(response.resultObj?.priorityCount||[])
      setHiShowPostCode(response.resultObj?.hiShowPostcode );
      setBuyerJobBasketCount(response.resultObj?.buyerJobBasketCount||0);
    } else {
      message.error(response?.resultObj?.message);
    }
    setIsLoading(false);
  };
  const getBuyerDetails = async () => {
    setIsLoading(true);
    const response = await APIUtils.apiCall('GET', WebLinks.GetBuyerDetails + buyerId);
    if (!response.isCatch && response.resultObj?.statusCode === "2000") {
      setIsClickPost(response.resultObj?.data?.clickPost);
      setName(response.resultObj?.data?.name);
      jobBoardBoostsList(null,response.resultObj?.data?.clickPost);
      setIsLoading(false);
    } else {
      setIsLoading(false);
    }
  };
  //Reset
  const resetStateVariable=()=>{
    setBoostJobsData([]);
    setBoostJobsCount(0);
    setPriorityCountList([])
    setHiShowPostCode(false);
  };
  //Function to get priority list
  const getBuyerPrioritylist=async()=>{
    let url = WebLinks.GetBuyerPriorityFilters + buyerId;    
    const response = await APIUtils.apiCall('GET', url);
    if (!response.isCatch && response.resultObj) {
      setPriorityList(response.resultObj.data);
      let temppriorityColorCode=[];
      setPriorityCheck(response?.resultObj?.data?.length>5?"t":"f");
      response?.resultObj?.data.forEach(element => {
        let priorityObj={
          value:element.priorityLevel.toString(),
          colorValue:response?.resultObj?.data?.length>5?GetCustomPriorityLevelIcons(element.priorityLevel):GetPriorityLevelColors(element.priorityLevel),
          customName:element.customName,
          prioritycount:getPriorityCount(element.priorityLevel,response?.resultObj?.priorityCount)
        }
        temppriorityColorCode.push(priorityObj);
        setPriorityColorCode(temppriorityColorCode);
      });
    } else {
      setPriorityList([]);
      setPriorityColorCode([]);
      message.error(response?.resultObj?.message);
    }
  };

  //Function to get the Priority Level to change multiple jobs.
  const onSelectChange = (selectedRowKeys, selectedRows) => {
    setSelectedRowKeys(selectedRowKeys);
  };
  const rowSelection = {
    selectedRowKeys,
    onChange: (selectedRowKeys, selectedRows) => onSelectChange(selectedRowKeys, selectedRows),
  };

  //Function to Update priority levels
  const UpdateBoostBasket = async (selectedIds, multiple = false) => {
    setIsLoading(true);
    let updateBoostPayload;
    updateBoostPayload={
      buyerId:buyerId,
      jobId:selectedIds,
      UpdatedByBuyerId:Cookies.get(constants.COOKIE_LOGGED_BUYER_ID)
    }
    const boostJobResponse = await APIUtils.apiCall("POST", WebLinks.InsertJobBoostBasket , JSON.stringify(updateBoostPayload));
    if (boostJobResponse.isCatch) {
      message.error(boostJobResponse.resultObj.message);
    } else {
      let tempBasketCount=buyerJobBasketCount;
      if (multiple) {
        let tempBoostData = [...boostJobsData];
        selectedIds.map((selectedId,index)=>{
          let itemIndex = tempBoostData.findIndex(item => item.id === selectedId);
          tempBoostData[itemIndex].isJobAdded = true;
        })
        tempBasketCount=tempBasketCount+selectedIds.length;
        setBoostJobsData(tempBoostData);
      }else{
        tempBasketCount=tempBasketCount+1
      }
      setBuyerJobBasketCount(tempBasketCount);
      setSelectedRowKeys([]);
      message.success(boostJobResponse.resultObj.message);
    }
    setIsLoading(false);
  }

  //Function to change the Priority Level of an individual job.
  const singleAddToBasket = async (id) => {
    let tempBoostJobData = [...boostJobsData];
    let itemIndex = tempBoostJobData.findIndex(item => item.id === id);
    tempBoostJobData[itemIndex].isJobAdded = true;
    setBoostJobsData(tempBoostJobData);
    let tempList = [];
    tempList.push(id);
    UpdateBoostBasket(tempList);
  };

  //Function to change the Priority Level for multiple jobs.
  const addJobsToBasket = () => {
    UpdateBoostBasket(selectedRowKeys, true);
  };

  const handleCancel = () => {
    formSearch.resetFields();
    formPriority.resetFields();
    setBoostViewModalOpen(false);
    jobBoardBoostsList(null);
  };

  //Function on search
  const handleSearch = (value) => {
    setSearchedValue(value);
  };

  const filterPriority = (value) => {
    jobBoardBoostsList(value,isClickPost);
  }

  // Table columns
  const boostJobsColumns = [
    {
      title: 'id',
      dataIndex: 'id',
      key: 'id',
      hidden: true
    },
    {
      title: 'Posted',
      dataIndex: 'posted',
      key: 'posted',
      sorter: (a, b) => moment(a.posted).valueOf() - moment(b.posted).valueOf(),
      render: (text) =>
      (
        <span className='whitespace-nowrap'>{moment(text).format(constants.DateFormat2)}</span>
      )
    },
    {
      title: 'Priority',
      key: 'priority',
      dataIndex: 'priority',
      align: 'center',
      sorter: (a, b) => a.priority - b.priority,
      render: (_, record) => (
        <Select
          disabled={true}
          value={record.priority}
          className='w-14 select-readonly'
          options={priorityCheck==="t"?constants.PriorityConstantList2:constants.PriorityConstantList1}
        />
      ),
    },
    {
      title: 'Job Title',
      dataIndex: 'title',
      key: 'jobTitle',
      sorter: (a, b) => a.title.localeCompare(b.title),
    },
    {
      title: 'Reference',
      dataIndex: 'reference',
      key: 'reference',
      sorter: (a, b) =>{
        const referenceA = parseInt(a.reference.match(/\d+/)[0], 10);
        const referenceB = parseInt(b.reference.match(/\d+/)[0], 10); 
        return referenceA - referenceB;
      },
    },
    {
      title: 'Location',
      dataIndex: 'place',
      key: 'place',
      sorter: (a, b) => a.place.localeCompare(b.place),
    },
    {
      title: 'Postcode',
      dataIndex: 'postcode',
      key: 'postcode',
      hidden: !HiShowPostCode,
      sorter: (a, b) =>{
        if (a.postcode === '-') return -1; // '-' comes first
        if (b.postcode === '-') return 1; // '-' comes first
        return a.postcode.localeCompare(b.postcode);
      },
    },
    {
      title: 'Actions',
      key: 'action',
      align: 'center',
      render: (_, record) => (
        <Space>
          {record?.isJobAdded?
          <Tooltip title="Added to Basket">
          <CheckOutlined 
          className='text-xl text-lime-500' /></Tooltip>:
          <Tooltip title="Add To Basket" zIndex='1000'>
              <Button size='small' onClick={() => singleAddToBasket(record.id)} icon={<PlusOutlined />}>Add To Basket</Button>
          </Tooltip>}
        </Space>
      ),
    }
  ];
  // Table end
  const filteredDataSource = boostJobsData.filter((record) =>{
    if (boostJobsColumns.some((column) => {
      const dataIndex = column.dataIndex;
      if (dataIndex) {
        const cellValue = record[dataIndex];
        if(dataIndex === 'id'){
          const ids = searchedValue.split(',').map(id => id?.trim()).filter(id => id !== '');
          if(ids.some(id => record[dataIndex].toString().toLowerCase().includes(id?.toLowerCase()))){
            return true;
          }else{
            return false;
          }
        } 
        else {
          // Handle standard columns
          const cellValueString = cellValue && cellValue.toString().toLowerCase();
          if (cellValueString && cellValueString.includes(searchedValue.toLowerCase())) {
            return true;
          }
        }
      }
      return false; // Skip undefined or null values
    })) {
      return true; // If any column has a match, include the record
    }
    return false; // Skip records with no matches in any column
  });

  return (
    <div>
      <div className="flex justify-end mb-3 text-xs">
            <Space wrap>
            </Space>
      </div>
      <div className="flex justify-between items-center gap-1">
        <Space>
          <div className="uppercase font-bold text-xl">Your Jobs</div>
            <span>{boostJobsCount} Jobs Listed </span>{props?.buyerId?<span className='font-bold text'> -{name}</span>:null}
        </Space>
        <Button type="primary" onClick={()=>setBoostViewModalOpen(true)} icon={<Badge count={buyerJobBasketCount} size='small'><ShoppingCartOutlined className='!text-white text-xl' /></Badge>}>View Basket</Button>
      </div>
      <Divider className="my-3" />
      <div className="flex flex-wrap justify-between items-center gap-1 mb-3">
        <Tooltip title="Add selected jobs to basket">
          <Button className='warning-btn uppercase font-bold' onClick={() => addJobsToBasket()} icon={<PlusOutlined />} disabled={selectedRowKeys.length === 0}>Add To Basket</Button>
        </Tooltip>
        <Space>
          <Form
            form={formPriority}
          >
            <Form.Item name="priorityField" className='mb-0'>
              <Select
                onChange={filterPriority}
                defaultValue={"-1"}
                placeholder="Please select"
                className="w-full md:w-60"
              >
                <Option value="-1">
                  <Space>
                    Priority Filter - Show All
                  </Space>
                </Option>
                {priorityColorCode?.map((item => {
                  return (
                    <Option value={item.value}>
                      <Space>
                        <div className={item.colorValue}></div>
                        {item.customName}
                      </Space>
                    </Option>
                  )
                }))}
              </Select>
            </Form.Item>
          </Form>
          <Form
            form={formSearch}
          >
            <Form.Item name="searchField" className='mb-0'>
              <Search
                placeholder="Search"
                allowClear
                onSearch={(e) => handleSearch(e)}
                onChange={(e) => { handleSearch(e.target.value); }}
                enterButton
                className="w-full md:w-60"
              />
            </Form.Item>
          </Form>
        </Space>
      </div>
      <div className="overflow-x-auto">
        <Table
          loading={isLoading}
          size='small'
          rowSelection={{
            type: 'checkbox',
            ...rowSelection,
            selectedRowKeys: selectedRowKeys
          }}
          rowKey="id"
          columns={boostJobsColumns}
          dataSource={filteredDataSource}
          pagination={{
            total: filteredDataSource?.length || 0,
            showSizeChanger: true,
            pageSizeOptions: ["200", "500", "1000", "2000"],
            defaultPageSize: 200,
            onChange: (page,pageSize) => {setCurrentPage(page); setPageSize(pageSize)},
          }}
          footer={(paginationData) =>
            `Showing ${Math.min(((currentPage - 1) * pageSize + 1), filteredDataSource?.length || 0)} to ${Math.min((currentPage * pageSize), filteredDataSource?.length || 0)} of ${filteredDataSource?.length || 0} entries`
          }
        />
      </div>
      <Modal
        title={""}
        open={boostViewModalOpen}
        onCancel={handleCancel}
        //okText={<span className="capitalize">Save</span>}
       // onOk={() => form.submit()}
        style={{ top: 20 }}
        width={1000}
        footer={[]}
        destroyOnClose={true}
      >
        <JobBoardBasketView/>
      </Modal>
    </div>
  );
};

export default JobboardBoosts;