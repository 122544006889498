import { MinusCircleOutlined, PlusOutlined, DoubleRightOutlined } from '@ant-design/icons';
import { Button, Form, Input, Divider, Card, Typography, Space, message } from 'antd';
import { useHistory } from 'react-router-dom';
import constants from '../../constants/constants';
import Cookies from 'js-cookie';
import APIUtils from '../../api/APIUtils';
import WebLinks from '../../api/WebLinks';
import { useState } from 'react';

const { TextArea } = Input;
const { Text } = Typography;

const BulkHire = (props) => {
  const buyerId = Cookies.get(constants.COOKIE_SELECTED_BUYER_ID)
  const history = useHistory();

  const [emails, setEmails] = useState([]);
  const [emailInput, setEmailInput] = useState('');
  const placeholderText = `Please enter the candidate's emails. Only one email per line.
  Example:
  email1@example.com
  email2@example.com
  email3@example.com
  email4@example.com
  email5@example.com`;

  //FUnction on final proceed
  const onProceedClick = async (values) => {
    let payload = []
    // payload.push(values.user.email)
    // values.users?.map((item) => {
    //   payload.push(item.email)
    // })
    payload=values?values:[];
    const url = WebLinks.SubmitBulkHire + buyerId;
    const response = await APIUtils.apiCall('POST', url, JSON.stringify(payload));
    if (!response.isCatch && response.resultObj.statusCode === "2000") {
      history.push('/set-bulk-hire', { data: response.resultObj, emails: payload  });
    }
  }

  const handleInputChange = (e) => {
    setEmailInput(e.target.value);
  };
  const validateEmail = (email) => {
    // Regular expression for email validation 
    // const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    //const emailRegex = /^[a-zA-Z0-9_.±]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$/;
    const emailRegex= /^(?!.*\s).*@.*$/;
    return emailRegex.test(email);
  };
  const handleAddEmails = () => {
    const newEmails = emailInput
      .split(/[\s,]+/)
      .filter((email) => email.trim() !== '');
      const validEmails = newEmails.filter(validateEmail);
      if (validEmails.length !== newEmails.length) {
        message.error('Please enter valid email addresses.');
      } else {
        setEmails([...emails, ...validEmails]);
        onProceedClick(validEmails);
        setEmailInput('');
      }
  };

  return (
    <div>
      <>
        <div className='uppercase font-bold text-xl'>Set candidates to be hired in bulk</div>
        <Divider className='mt-2 mb-3' />
        {/* <div>
          <p className='mb-3'>Please enter the candidate's emails</p>
        </div> */}
        {/* <Card className='w-2/3'>
          <Form
            name="dynamic_form_nest_item"
            autoComplete="off"
            onFinish={onProceedClick}
          >

            <Form.Item
              name={["user", 'email']}
              rules={[
                {
                  type: 'email',
                  message: 'Enter a valid email',
                },
              ]}
              className='mb-2 w-full'
            >
              <Input placeholder='example@email.com' />
            </Form.Item>
            <Form.List name="users">
              {(fields, { add, remove }) => (
                <>
                  {fields.map(({ key, name, ...restField }) => (
                    <div
                      key={key}
                      className='flex items-center gap-2'
                    >
                      <Form.Item
                        {...restField}
                        name={[name, 'email']}
                        rules={[
                          {
                            type: 'email',
                            message: 'Enter a valid email',
                          },
                        ]}
                        className='mb-2 w-[95%]'
                      >
                        <Input placeholder='example@email.com' />
                      </Form.Item>
                      <MinusCircleOutlined className='mb-2' onClick={() => remove(name)} />
                    </div>
                  ))}
                  <Form.Item className='mb-3'>
                    <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                      Add More
                    </Button>
                  </Form.Item>
                </>
              )}
            </Form.List>
            <Form.Item className='mb-0'>
              <Button type="primary" htmlType="submit" >
                Proceed <DoubleRightOutlined className='relative bottom-px' />
              </Button>
            </Form.Item>
          </Form>
        </Card> */}
        {/* <Space direction="vertical"> */}
        <div>
          <p className='mb-3'>Please enter the candidate's emails</p>
        </div>
        <Card className='w-2/3'>
        <Form
          autoComplete="off"
        >
          <Form.Item>
          <TextArea
            rows={8}
            value={emailInput}
            onChange={handleInputChange}
            placeholder={placeholderText}
            className='mb-2 w-full'
          />
          </Form.Item>
          <Form.Item>
          <Button type="primary" onClick={()=>handleAddEmails()}>
            Proceed <DoubleRightOutlined className='relative bottom-px' />
          </Button>
          </Form.Item>
        </Form>
        </Card>
      {/* </Space> */}
      </>
    </div>
  );
};

export default BulkHire;