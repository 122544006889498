import { Card, Form, Col, Row, Space, Button, Image, Pagination, Divider, message, Empty, Spin, DatePicker, Select, Table } from 'antd';
import Icon, { CheckCircleFilled, GoogleOutlined, CloseCircleOutlined, EllipsisOutlined, } from '@ant-design/icons';
import moment from 'moment';
import { useEffect, useState, useRef } from 'react';
import Cookies from 'js-cookie';
import WebLinks from '../../api/WebLinks';
import APIUtils from '../../api/APIUtils';
import constants from '../../constants/constants';
import GoogleSVG from '../../assets/Google_2015_logo.png';
import dayjs from 'dayjs';
import { debounce } from 'lodash';

const { RangePicker } = DatePicker;

const GoogleLandingPages = () => {
	const [Loading, setLoading] = useState(false);
	const [data, setData] = useState([]);
	const [total, setTotal] = useState(0);
	const [currentPage, setCurrentPage] = useState(1);
	const [pageSize, setPageSize] = useState(constants.defaultSocialPagesize);
	const [rangePickerValue, setRangePickerValue] = useState([]);
	const [status, setStatus] = useState("");
	const [form] = Form.useForm();
	const [tableWidth, setTableWidth] = useState(0);
	const tableContainerRef = useRef(null);
	const topScrollRef = useRef(null);

	useEffect(() => {
		if (tableContainerRef.current) {
			setTableWidth(tableContainerRef.current.scrollWidth);
		}
	}, [data]);

	const handleTopScroll = (e) => {
		if (tableContainerRef.current) {
			tableContainerRef.current.scrollLeft = e.target.scrollLeft;
		}
	};

	const handleTableScroll = (e) => {
		if (topScrollRef.current) {
			topScrollRef.current.scrollLeft = e.target.scrollLeft;
		}
	};

	const getJobGooglePages = async (TablePageSize = pageSize, currentPagenum = currentPage, startDate, endDate, campaign) => {
		setLoading(true);
		let url = WebLinks.GetGoogleCampaign + Cookies.get(constants.COOKIE_SELECTED_BUYER_ID);
		const response = await APIUtils.apiCall('GET', url + "&page=" + currentPagenum + "&perPage=" + TablePageSize + "&fromDate=" + startDate + "&toDate=" + endDate + "&Campaign=" + campaign);
		if (!response.isCatch && response.resultObj.statusCode === "2000") {
			setData(response?.resultObj?.data || []);
			setTotal(response?.resultObj?.getCount || 0);
		} else {
			setData([]);
			setTotal(0);
			message.error(response?.resultObj?.message || constants.NO_DATA);
		}
		setLoading(false);
	};

	const renderJobGooglePageCards = (item, index) => {
		return (
			<div className='text-xs'>
				<div className='flex justify-between items-center'>
					{
						item?.active ? (
							<Space>
								<CheckCircleFilled className='text-green-500' />
								<span>Active</span>
							</Space>
						) : (
							<Space>
								<CloseCircleOutlined className='text-red-500' />
								<span>Inactive</span>
							</Space>
						)}
					<Button type='text' icon={<EllipsisOutlined />} />
				</div>
				<div>
					Started running on {moment(item?.createdOn).format('MMM D, YY')}
				</div>
				<Space>
					<span className='text-xs'>Platforms:</span>
					<Space size={3}>
						<Icon  component={() => (<img src={GoogleSVG} width={'14%'} alt='icon' />)} />
					</Space>
				</Space>
				<div>
					<label className='font-bold'>Job Family: </label>
					<span className='font-semibold'>{item?.jobFamily}</span>
				</div>
				<div>
					<label className='font-bold'>Location: </label>
					<span className='font-semibold'>{item?.location}</span>
				</div>
				<div>
					<label className='font-bold'>Current Monthly Budget: </label>
					<span className='font-semibold'>{item?.monthlyBudget}</span>
				</div>
				<Space wrap>
					<div className='text-xs'>
						<label className='font-bold'>Applications: </label>
						<span className='font-semibold'>{item?.googleLandingPageReport?.applications || 0}</span>
					</div>
					<div className='text-xs'>
						<label className='font-bold'>Quality Applications: </label>
						<span className='font-semibold'>{item?.googleLandingPageReport?.qualityApplications || 0}</span>
					</div>
					<div className='text-xs'>
						<label className='font-bold'>Interviews: </label>
						<span className='font-semibold'>{item?.googleLandingPageReport?.interview || 0}</span>
					</div>
					<div className='text-xs'>
						<label className='font-bold'>Hires: </label>
						<span className='font-semibold'>{item?.googleLandingPageReport?.hired || 0}</span>
					</div>
				</Space>
				<Card className='w-full mt-2' hoverable bodyStyle={{ padding: 10 }}>
					<div className='mt-1 text-center bg-gray-200'>
						<Image
							preview={false}
							src={item?.landingPageImage}
						/>
					</div>
				</Card>
			</div>
		)
	}

	const handleSizeChange = (current, newSize) => {
		setPageSize(newSize);
		setCurrentPage(1);
		getJobGooglePages(newSize, 1, rangePickerValue[0], rangePickerValue[1]);
	};
	//Function runs when range picker value is changed
	const RangePickerChanged = debounce((_, dateStrings) => {
		setRangePickerValue([
			dayjs(dateStrings[0], constants.MonthFormat),
			dayjs(dateStrings[1], constants.MonthFormat),
		]);
		getJobGooglePages(pageSize, 1, dateStrings[0], dateStrings[1], status);
	}, 500);
	useEffect(() => {
		const date1 = dayjs().startOf('month')
		const date2 = dayjs().endOf('month')
		form.setFieldsValue({
			rangePicker: [
				date1,
				date2,
			],
		});
		setRangePickerValue([
			date1.format(constants.MonthFormat),
			date2.format(constants.MonthFormat),
		]);
		getJobGooglePages(pageSize, 1, date1.format(constants.MonthFormat), date2.format(constants.MonthFormat), "")
	}, []);

	const handlePageChange = (page, size) => {
    setCurrentPage(page);
    getJobGooglePages(size, page, rangePickerValue[0], rangePickerValue[1]);
  };

	return (
		<div>
			<div className='flex flex-wrap justify-between items-center'>
				<div className='uppercase font-bold text-xl'>Google Landing Pages</div>
				<Form form={form}>
					<Space size="large">
						<Form.Item name="rangePicker" className='mb-0'>
							<RangePicker picker="month"
								placeholder={["From", "To"]}
								onChange={RangePickerChanged}
								//value={rangePickerValue}

								format={constants.MonthFormat} />
						</Form.Item>
						<Form.Item label="Status" className='mb-0'>
							<Select
								defaultValue=""
								className='!w-36'
								onChange={(e) => { setStatus(e); getJobGooglePages(pageSize, 1, dayjs(rangePickerValue[0]).format(constants.MonthFormat), dayjs(rangePickerValue[1]).format(constants.MonthFormat), e) }}
								options={[
									{
										value: '',
										label: 'Show All',
									},
									{
										value: 'campaign',
										label: 'Campaign',
									},
								]}
							/>
						</Form.Item>
					</Space>
				</Form>
			</div>
			<Divider className='my-3' />
			<Spin spinning={Loading}>
				{data.length > 0 ? (
					<div className="mt-3 relative">
						{/* Top Scrollbar */}
						<div
							ref={topScrollRef}
							className='overflow-x-auto overflow-y-hidden h-4 w-full sticky top-0 bg-white z-10'
							onScroll={handleTopScroll}
						>
							<div style={{ width: tableWidth, height: 1 }} />
						</div>

						{/* Table */}
						<div
							ref={tableContainerRef}
							className='overflow-x-auto'
							onScroll={handleTableScroll}
						>
							<Table
								dataSource={[{}]} // Single-row table, data comes from columns
								rowKey={() => "single-row"}
								showHeader={false}
								pagination={false}
								bordered
								className='google-table'
							>
								{data.map((item, index) => (
									<Table.Column
										key={index}
										render={() => renderJobGooglePageCards(item, index)}
									/>
								))}
							</Table>
						</div>
						{/* Pagination */}
						{/* <div className='mt-3 flex justify-end'>
						<Pagination
							pageSize={pageSize}
							pageSizeOptions={['24', '48', '96', '192']}
							showSizeChanger
							current={currentPage}
							total={total}
							onChange={handlePageChange}
							onShowSizeChange={handleSizeChange}
						/>
						</div> */}
					</div>
				) : (
					<Empty />
				)}
			</Spin>
		</div>
	);
};

export default GoogleLandingPages;
