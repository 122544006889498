import React from "react";
import { Switch, Route } from "react-router-dom";
import Layouts from "../views/common/Layout";
import { RouteWithLayout } from "./helper";
import Login from "../views/common/cred/Login";
import Profile from "../views/common/cred/Profile";
import PriorityFilters from "../views/PriorityFilters";
import ManageSuppliers from "../views/ManageSuppliers";
import DistributionRules from "../views/distribution-rules/DistributionRules";
import JobUploadHistory from "../views/JobUploadHistory";
import SocialMediaPromo from "../views/SocialMediaPromo";
import ClickReport from "../views/ClickReport";
import ApplicationReport from "../views/app-report/ApplicationReport";
import OccupationAdmin from "../views/admin/OccupationAdmin";
import OccupationFilterAdmin from "../views/admin/OccupationFilterAdmin";
import Candidates from "../views/Candidates";
import DownloadFile from "../views/DownloadFile";
import MultiBudgetAdmin from "../views/sub-buyer-admin/MultiBudgetAdmin";
import MultiApplicationReport from "../views/sub-buyer-admin/MultiApplicationReport";
import BulkHire from "../views/candidates/BulkHire";
import SetBulkHire from "../views/candidates/SetBulkHire";
import YourJobsAll from "../views/YourJobsAll";
import JobsInFeedBlock from "../views/JobsInFeedBlock";
import PriorityRules from "../views/PriorityRules";
import QualityFiltering from "../views/QualityFiltering";
import QualityFilters from "../views/quality-filters/QualityFilters";
import SocialConfigure from "../views/SocialConfigure";
import BudgetContainer from "../views/budget/BudgetContainer";
import MultiJobAmin from "../views/sub-buyer-admin/MultiJobAdmin";
import MultiCandidateAdmin from "../views/sub-buyer-admin/MultiCandidateAdmin";
import ClickReportTabs from "../views/click-report/ClickReportTab";
import MultiClickReport from "../views/sub-buyer-admin/MultiClickReport";
import TikTok from "../views/TikTok";
import PrivacyPolicy from "../views/common/policy/PrivacyPolicy";
import TermsAndConditions from "../views/common/policy/TermsAndConditions";
import CookiePolicy from "../views/common/policy/CookiePolicy";
import DataProcessingPolicy from "../views/common/policy/DataProcessingPolicy";
import Dashboard from "../views/dashboard/Dashboard";
import TikTokPromo from "../views/TikTokPromo";
import MultiDashboard from "../views/dashboard/MultiDashboard";
import JobsSinglePost from "../views/job-single-post/JobsSinglePost";
import EditJobDetails from "../views/jobs/EditJobDetails";
import MultiCostCenter from "../views/sub-buyer-admin/MultiCostCenter";
import JobsInfeedOverrideTabs from "../views/JobsInfeedOverrideTabs";
import MultiPriorityFilters from "../views/sub-buyer-admin/MultiPriorityFilters";
import MultiPriorityRules from "../views/sub-buyer-admin/MultiPriorityRules";
import BudgetContainerMulti from "../views/budget/BudgetContainerMulti";
import ManageSuppliersAdmin from "../views/ManageSuppliersAdmin";
import MultiSupplierAdmin from "../views/sub-buyer-admin/MultiSupplierAdmin";
import ActivityLogsTabs from "../views/logs/ActivityLogsTabs";
import YourJobsContainer from "../views/jobs/YourJobsContainer";
import MultiYourJobsContainer from "../views/sub-buyer-admin/MultiYourJobsContainer";
import YourJobsContainerAdmin from "../views/jobs/YourJobsContainerAdmin";
import YourJobsApplications from "../views/jobs/YourJobsApplications";
import GoogleLandingPages from "../views/campaign/GoogleLandingPages";
const Routes = ({ location, ...rest }) => {
	return (
		<div>
			<Switch>
				<Route
					exact
					path="/login"
					component={Login}
				/>
				<Route
					exact
					path="/data-policy"
					component={DataProcessingPolicy}
				/>
				<Route
				exact
				path="/cookie-policy"
				component={CookiePolicy}
				/>
				<Route
				exact
				path="/terms-and-conditions"
				component={TermsAndConditions}
				/>
				<Route
				exact
				path="/privacy-policy"
				component={PrivacyPolicy}
				/>
				<RouteWithLayout
					exact
					path="/"
					layout={Layouts}
					location={location}
					component={YourJobsContainer}
					{...rest}
				/>
				<RouteWithLayout
					exact
					path="/profile"
					layout={Layouts}
					location={location}
					component={Profile}
					{...rest}
				/>
				<RouteWithLayout
					exact
					path="/activity-logs"
					layout={Layouts}
					location={location}
					component={ActivityLogsTabs}
					{...rest}
				/>
				<RouteWithLayout
					exact
					path="/priority-filters"
					layout={Layouts}
					location={location}
					component={PriorityFilters}
					{...rest}
				/>
				<RouteWithLayout
					exact
					path="/priority-rules"
					layout={Layouts}
					location={location}
					component={PriorityRules}
					{...rest}
				/>
				<RouteWithLayout
					exact
					path="/your-jobs"
					layout={Layouts}
					location={location}
					component={YourJobsContainer}
					{...rest}
				/>
				<RouteWithLayout
					exact
					path="/your-jobs-new"
					layout={Layouts}
					location={location}
					component={YourJobsAll}
					{...rest}
				/>
				<RouteWithLayout
					exact
					path="/your-jobs-admin"
					layout={Layouts}
					location={location}
					component={YourJobsContainerAdmin}
					{...rest}
				/>
				<RouteWithLayout
					exact
					path="/budgets"
					layout={Layouts}
					location={location}
					component={BudgetContainer}
					{...rest}
				/>
				<RouteWithLayout
					exact
					path="/supplier"
					layout={Layouts}
					location={location}
					component={ManageSuppliers}
					{...rest}
				/>
				<RouteWithLayout
					exact
					path="/supplier-admin"
					layout={Layouts}
					location={location}
					component={ManageSuppliersAdmin}
					{...rest}
				/>
				<RouteWithLayout
					exact
					path="/distribution-rules"
					layout={Layouts}
					location={location}
					component={DistributionRules}
					{...rest}
				/>
				<RouteWithLayout
					exact
					path="/history"
					layout={Layouts}
					location={location}
					component={JobUploadHistory}
					{...rest}
				/>
				<RouteWithLayout
					exact
					path="/social"
					layout={Layouts}
					location={location}
					component={SocialMediaPromo}
					{...rest}
				/>
				<RouteWithLayout
					exact
					path="/google-landing-pages"
					layout={Layouts}
					location={location}
					component={GoogleLandingPages}
					{...rest}
				/>
				<RouteWithLayout
					exact
					path="/tiktok"
					layout={Layouts}
					location={location}
					component={TikTokPromo}
					// component={TikTok}
					{...rest}
				/>
				<RouteWithLayout
					exact
					path="/click-report"
					layout={Layouts}
					location={location}
					component={ClickReportTabs}
					{...rest}
				/>
				<RouteWithLayout
					exact
					path="/application-report"
					layout={Layouts}
					location={location}
					component={ApplicationReport}
					{...rest}
				/>
				<RouteWithLayout
					exact
					path="/candidates"
					layout={Layouts}
					location={location}
					component={Candidates}
					{...rest}
				/>
				<RouteWithLayout
					exact
					path="/DownloadFile"
					layout={Layouts}
					location={location}
					component={DownloadFile}
					{...rest}
				/>
				<RouteWithLayout
					exact
					path="/dashboard"
					layout={Layouts}
					location={location}
					component={Dashboard}
					{...rest}
				/>
				{/* Admin pages */}
				<RouteWithLayout
					exact
					path="/budgets-admin"
					layout={Layouts}
					location={location}
					component={BudgetContainer}
					{...rest}
				/>
				<RouteWithLayout
					exact
					path="/multi-budgets"
					layout={Layouts}
					location={location}
					component={BudgetContainerMulti}
					{...rest}
				/>
				<RouteWithLayout
					exact
					path="/your-jobs-occupations"
					layout={Layouts}
					location={location}
					component={OccupationAdmin}
					{...rest}
				/>
				<RouteWithLayout
					exact
					path="/your-jobs-occupations-filter"
					layout={Layouts}
					location={location}
					component={OccupationFilterAdmin}
					{...rest}
				/>
				<RouteWithLayout
					exact
					path="/multi-budget-admin"
					layout={Layouts}
					location={location}
					component={MultiBudgetAdmin}
					{...rest}
				/>
				<RouteWithLayout
					exact
					path="/multi-supplier-admin"
					layout={Layouts}
					location={location}
					component={MultiSupplierAdmin}
					{...rest}
				/>
				<RouteWithLayout
					exact
					path="/multi-application-report"
					layout={Layouts}
					location={location}
					component={MultiApplicationReport}
					{...rest}
				/>
				<RouteWithLayout
					exact
					path="/multi-job-admin"
					layout={Layouts}
					location={location}
					component={MultiYourJobsContainer}
					{...rest}
				/>
				<RouteWithLayout
					exact
					path="/multi-click-report"
					layout={Layouts}
					location={location}
					component={MultiClickReport}
					{...rest}
				/>
				<RouteWithLayout
					exact
					path="/multi-candidate-admin"
					layout={Layouts}
					location={location}
					component={MultiCandidateAdmin}
					{...rest}
				/>
				<RouteWithLayout
					exact
					path="/multi-cost-center"
					layout={Layouts}
					location={location}
					component={MultiCostCenter}
					{...rest}
				/>
					<RouteWithLayout
					exact
					path="/multi-priority-filters"
					layout={Layouts}
					location={location}
					component={MultiPriorityFilters}
					{...rest}
				/>
					<RouteWithLayout
					exact
					path="/multi-priority-rules"
					layout={Layouts}
					location={location}
					component={MultiPriorityRules}
					{...rest}
				/>
				<RouteWithLayout
					exact
					path="/bulk-hire"
					layout={Layouts}
					location={location}
					component={BulkHire}
					{...rest}
				/>
				<RouteWithLayout
					exact
					path="/set-bulk-hire"
					layout={Layouts}
					location={location}
					component={SetBulkHire}
					{...rest}
				/>
				<RouteWithLayout
					exact
					path="/jobs-infeed/:supplierId"
					layout={Layouts}
					location={location}
					component={JobsInfeedOverrideTabs}
					{...rest}
				/>
				<RouteWithLayout
					exact
					path="/your-jobs-applications/:jobId"
					layout={Layouts}
					location={location}
					component={YourJobsApplications}
					{...rest}
				/>
				<RouteWithLayout
					exact
					path="/jobs-infeed-block/:supplierId"
					layout={Layouts}
					location={location}
					component={JobsInFeedBlock}
					{...rest}
				/>
				<RouteWithLayout
					exact
					path="/jobs-singlepost/:supplierId"
					layout={Layouts}
					location={location}
					component={JobsSinglePost}
					{...rest}
				/>
				<RouteWithLayout
					exact
					path="/edit-job/:jobId"
					layout={Layouts}
					location={location}
					component={EditJobDetails}
					{...rest}
				/>
				<RouteWithLayout
					exact
					path="/quality-filtering"
					layout={Layouts}
					location={location}
					component={QualityFiltering}
					{...rest}
				/>
				<RouteWithLayout
					exact
					path="/quality-filters"
					layout={Layouts}
					location={location}
					component={QualityFilters}
					{...rest}
				/>
				<RouteWithLayout
					exact
					path="/socialconfigure"
					layout={Layouts}
					location={location}
					component={SocialConfigure}
					{...rest}
				/>
				<RouteWithLayout
					exact
					path="/multi-dashboard"
					layout={Layouts}
					location={location}
					component={MultiDashboard}
					{...rest}
				/>
			</Switch>
		</div>
	);
};
export default Routes;
